import useAppContext from "@/src/components/appContext/useAppContext";
import { FeatureToggle } from "@/src/constants/featureFlags";

export function useFeatureFlag() {
  const { ctx } = useAppContext();

  const isEnabled = (key: FeatureToggle) =>
    ctx?.featureFlags ? ctx.featureFlags[key] : false;

  return {
    isApiDebugEnabled: isEnabled(FeatureToggle.EnableApiDebug),
    isUIDebugEnabled: isEnabled(FeatureToggle.EnableUiDebug),
    isTreeSelectEnabled: isEnabled(FeatureToggle.TreeSelect),
    isImageEnrichmentEnabled: isEnabled(FeatureToggle.ImageEnrichment),
    isAccountSettingsEnabled: isEnabled(FeatureToggle.AccountSettings),
    isLocaleProductsTableEnabled: isEnabled(FeatureToggle.LocaleProductsTable),
    isActivityHistoryEnabled: isEnabled(FeatureToggle.ActivityHistory),
  };
}
